<template>
<!-- When installing the webpage as an app (PWA),
this page is the start page. Its purpose is to
redirect the user to their choosen default page (Home or Schedule).
This can be changed in the Settings.
-->
<div></div>
</template>

<script>
export default {
  name: 'LandingPage',
  data: () => {
    return {}
  },
  created: function () {
    // Get the saved start page
    const landingPage = this.$store.getters.landingPage
    if (landingPage) {
      this.$router.push(landingPage)
    } else {
      this.$router.push('/')
    }
  }
}
</script>
